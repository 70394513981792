function Impressum() {

    return (
        <div className="Impressum">
            <p>Impressum</p>
        </div>
    );
}

export default Impressum;
