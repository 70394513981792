function Contact() {

    return (
        <div className="Contact">
            <p>Contact</p>
        </div>
    );
}

export default Contact;
