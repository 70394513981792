function LandingPage() {

    return (
        <div className="LandingPage">
            <p>LandingPage</p>
        </div>
    );
}

export default LandingPage;
