function NotFound() {

    return (
        <div className="NotFound">
            <p>NotFound</p>
        </div>
    );
}

export default NotFound;
